<template>
    <div class="app-container">
        <div id="problemsContainer">
            <Box :type="'error'" :active="true" v-if="inputProblems.length > 0">
                <div slot="box-body">
                    <span class="title-no-border">
                        <strong>{{ $t('publish.add.problems') }}:</strong>
                    </span>
                    <ul class="rawUl">
                        <li v-for="(problem, index) in inputProblems" :key="index">
                            <span v-html="problem"></span>
                        </li>
                    </ul>
                </div>
            </Box>
        </div>

        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <span>Optiuni</span>
            </div>
            <div class="body">
                <div class="sub-title">Adaugare prefix cod produs</div>
                <el-checkbox
                    :checked="!!options.forceInsert"
                    v-model="options.forceInsert"
                    label="Permite adaugarea de prefix la codul produsului pentru produsele ce exista pe site dar au categorie si producator diferit"
                    border
                ></el-checkbox>
            </div>
        </el-card>

        <div class="separator"></div>

        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <span>{{ $t('publish.add.add-header') }}</span>
                <span style="float: right">
                    <el-button type="primary" size="mini" icon="el-icon-plus" plain @click.native="saveProduct">{{
                        $t('publish.add.save')
                    }}</el-button>
                </span>
            </div>
            <div class="productDetails">
                <div class="title">
                    {{ $t('publish.add.category-title') }}
                </div>
                <div>
                    <div class="sub-title">{{ $t('publish.add.category') }}</div>
                    <c-select @selected="categorySelected"></c-select>
                </div>
                <div class="separator"></div>
                <div>
                    <div class="sub-title">{{ $t('publish.add.name') }}</div>
                    <el-input
                        placeholder="Ex: Telefon Mobil Samsung Galaxy S8 G950F 64GB 4G Black"
                        v-model="product.title"
                        show-word-limit
                        :maxlength="characterLimits.productName"
                    ></el-input>
                </div>
                <div class="separator"></div>
                <div>
                    <div class="sub-title">{{ $t('publish.add.brand') }}</div>
                    <el-input placeholder="Ex: Samsung" v-model="product.brand"></el-input>
                </div>
                <div class="separator"></div>
                <div>
                    <div class="sub-title">{{ $t('publish.add.product-model') }}</div>
                    <el-input placeholder="Ex: SM-G950FZKAROM" v-model="product.model"></el-input>
                    <div class="small info-text">(Recomandat minim 6 caractere)</div>
                </div>
                <div class="separator"></div>
                <div>
                    <div class="sub-title">{{ $t('publish.add.intern-id') }}</div>
                    <el-input placeholder="Ex: 123456" v-model="product.vendor_ext_id"></el-input>
                    <div class="small info-text">(Recomandat minim 6 caractere)</div>
                </div>
                <div class="separator"></div>
                <div>
                    <div class="sub-title">{{ $t('publish.add.price') }}</div>
                    <el-input placeholder="Ex: 101.3" v-model="product.price"></el-input>
                </div>
                <div class="separator"></div>
                <div>
                    <div class="sub-title">{{ $t('publish.add.old-price') }}</div>
                    <el-input placeholder="Ex: 129" v-model="product.oldPrice"></el-input>
                </div>
                <div class="separator"></div>
                <div v-if="!product.virtual">
                    <div class="sub-title">{{ $t('publish.add.dimensiuni_cm') }}</div>
                    <el-row :gutter="20">
                        <el-col :xs="24" :md="8">
                            <div class="sub-title">{{ $t('publish.add.dimensiuni_latime') }}</div>
                            <el-input-number
                                :controls="false"
                                class="delinput"
                                placeholder="Ex: 21"
                                v-model="product.dimensions.latime"
                            />
                        </el-col>
                        <el-col :xs="24" :md="8">
                            <div class="sub-title">{{ $t('publish.add.dimensiuni_inaltime') }}</div>
                            <el-input-number
                                placeholder="Ex: 11"
                                :controls="false"
                                class="delinput"
                                v-model="product.dimensions.inaltime"
                            />
                        </el-col>
                        <el-col :xs="24" :md="8">
                            <div class="sub-title">{{ $t('publish.add.dimensiuni_adancime') }}</div>
                            <el-input-number
                                placeholder="Ex: 5"
                                :controls="false"
                                class="delinput"
                                v-model="product.dimensions.adancime"
                            />
                        </el-col>
                    </el-row>
                </div>
                <div class="separator"></div>
                <div>
                    <div class="sub-title">{{ $t('publish.add.warranty-pf') }}</div>
                    <el-input id="warranty_pf" placeholder="Ex: 24" v-model="product.warranty"></el-input>
                </div>
                <div class="separator"></div>
                <div>
                    <div class="sub-title">{{ $t('publish.add.warranty-pj') }}</div>
                    <el-input id="warranty_pj" placeholder="Ex: 12" v-model="product.warranty_pj"></el-input>
                </div>
                <div class="separator"></div>
                <div>
                    <div class="sub-title">{{ $t('publish.add.stock') }}</div>
                    <el-input placeholder="Ex: 10" v-model="product.stoc"></el-input>
                </div>
                <div class="separator"></div>
                <div>
                    <div class="sub-title">Status</div>
                    <el-select v-model="product.status" placeholder="Select" class="fullWidth">
                        <el-option :label="$t('publish.add.active')" value="1"> </el-option>
                        <el-option :label="$t('publish.add.inactive')" value="0"> </el-option>
                    </el-select>
                </div>
                <div class="separator"></div>
                <div>
                    <div class="sub-title">{{ $t('publish.add.process-time') }}</div>
                    <el-input :placeholder="$t('publish.add.process-time-ph')" v-model="product.procesare_in">
                    </el-input>
                </div>
                <div class="separator"></div>
                <div>
                    <div class="sub-title">{{ $t('publish.add.weight') }} (Kg)</div>
                    <el-input v-model="product.products_weight"></el-input>
                </div>
                <div class="separator"></div>
                <div>
                    <div class="sub-title">{{ $t('publish.add.virtual') }} {{ $t('publish.add.virtual_extra') }}</div>
                    <el-select v-model="product.virtual" placeholder="Select" class="fullWidth">
                        <el-option :label="$t('publish.add.is_virtual')" :value="1"> </el-option>
                        <el-option :label="$t('publish.add.is_not_virtual')" :value="0"> </el-option>
                    </el-select>
                </div>
            </div>
            <div class="separator"></div>
            <div class="productFamily">
                <div class="title">
                    {{ $t('publish.add.family') }}
                </div>
                <template v-if="allCharacteristics.length > 0">
                    <!--					<div>-->
                    <!--						<div class="sub-title">{{$t('publish.add.family-id')}}</div>-->
                    <!--						<el-input :placeholder="$t('publish.add.family-id')" v-model="product.family_id"></el-input>-->
                    <!--					</div>-->
                    <!--					<div class="separator"></div>-->
                    <!--					<div>-->
                    <!--						<div class="sub-title">{{$t('publish.add.family-name')}}</div>-->
                    <!--						<el-input :placeholder="$t('publish.add.family-name')" v-model="product.family_name"></el-input>-->
                    <!--					</div>-->
                    <div>
                        <el-select v-model="product.family_id" @change="onFamilySelect">
                            <el-option
                                v-for="family in families"
                                :key="family.id"
                                :value="family.id"
                                :label="family['name']"
                            >
                            </el-option>
                        </el-select>
                        <el-button type="primary" @click="getFamilies">Reincarca lista familii </el-button>
                        <el-button type="success" @click="addNewFamily">Adauga familie noua </el-button>
                    </div>
                    <div class="separator"></div>
                    <template v-if="product.family_id != null && product.family_id !== ''">
                        <div class="sub-title">
                            <strong>{{ $t('publish.add.family-charact') }}</strong>
                            <el-button type="primary" icon="el-icon-plus" size="mini" @click="addFamilyCharact" plain>
                            </el-button>
                        </div>
                        <el-card
                            shadow="never"
                            class="product-family-card"
                            v-for="(item, i) in familyCharacts"
                            :key="i"
                        >
                            <div slot="header" class="clearfix">
                                <span class="sub-title">{{ $t('publish.add.type') }} #{{ i + 1 }}</span>
                                <el-button
                                    style="float: right"
                                    size="small"
                                    type="text"
                                    @click="removeFamilyCharact(i)"
                                >
                                    {{ $t('general.delete') }}</el-button
                                >
                            </div>
                            <div>
                                <div class="sub-title">{{ $t('publish.add.family-type') }}</div>
                                <el-select
                                    size="small"
                                    filterable
                                    v-model="familyCharacts[i].charact"
                                    :placeholder="$t('publish.add.family-type')"
                                    :no-data-text="$t('products.families.no-data-text')"
                                    class="fullWidth"
                                >
                                    <div v-for="fto in familyTypeOptions" :key="fto.value">
                                        <el-option :label="fto.label" :value="fto.value" :key="fto.value"> </el-option>
                                    </div>
                                </el-select>
                            </div>
                            <div class="separator"></div>
                            <div>
                                <div class="sub-title">{{ $t('publish.add.family-mode') }}</div>
                                <el-select
                                    size="small"
                                    v-model="familyCharacts[i].mode"
                                    :placeholder="$t('publish.add.family-mode')"
                                    class="fullWidth"
                                >
                                    <div v-for="item in familyModeOptions" :key="item.value">
                                        <el-option :label="item.label" :value="item.value" :key="item.value">
                                        </el-option>
                                    </div>
                                </el-select>
                            </div>
                            <div class="separator"></div>
                        </el-card>
                    </template>
                </template>
                <template v-else>
                    <div class="sub-title">
                        <strong>{{ $t('publish.add.select-category') }}</strong>
                    </div>
                </template>
            </div>
            <div class="separator"></div>
            <div class="productCharacts">
                <div class="title">
                    {{ $t('publish.add.characts') }}
                </div>
                <div v-if="hasCategory && allCharacteristics.length > 0">
                    <div v-for="(charact, x) in allCharacteristics" :key="x">
                        <div class="sub-title">
                            {{ charact.charact_name }}
                            <el-tooltip
                                class="item"
                                effect="dark"
                                :content="$t('publish.add.tooltip-1')"
                                placement="top-start"
                            >
                                <el-button
                                    type="primary"
                                    size="mini"
                                    icon="el-icon-plus"
                                    plain
                                    @click.native="updateCount(x)"
                                ></el-button>
                            </el-tooltip>
                            <el-tooltip
                                class="item"
                                effect="dark"
                                :content="$t('publish.add.tooltip-2')"
                                placement="top-start"
                            >
                                <el-button
                                    type="primary"
                                    size="mini"
                                    icon="el-icon-minus"
                                    plain
                                    @click.native="removeCharactValue(x)"
                                ></el-button>
                            </el-tooltip>
                            <el-tooltip
                                class="item"
                                effect="dark"
                                :content="$t('publish.add.tooltip-3')"
                                placement="top-start"
                                v-if="parseInt(charact.restrictions) !== 1 && charact.charact_value !== null"
                            >
                                <el-button
                                    style="margin-left: 0"
                                    type="warning"
                                    size="mini"
                                    icon="el-icon-tickets"
                                    plain
                                    @click.native="openModal(charact.charact_id, charact.restrictions)"
                                ></el-button>
                            </el-tooltip>
                        </div>
                        <div v-if="charact.charact_value === null">
                            <div v-for="i in charact.count" :key="i">
                                <el-input
                                    :ref="`input_${i}`"
                                    :key="i"
                                    @change="setCharactVal(i, charact['selected_' + i], charact)"
                                    show-word-limit
                                    :maxlength="characterLimits.characteristics"
                                    :placeholder="setPlaceholder(charact.priority)"
                                    :class="checkCharactPriority(charact.charact_id, charact.priority)"
                                    class="margin-bottom-10"
                                    v-model="charact['selected_' + i]"
                                ></el-input>
                            </div>
                        </div>
                        <div v-else>
                            <span v-for="i in charact.count" :key="i">
                                <el-select
                                    allow-create
                                    filterable
                                    v-model="charact['selected_' + i]"
                                    @change="setCharactVal(i, charact['selected_' + i], charact)"
                                    class="fullWidth margin-bottom-10"
                                    clearable
                                    :placeholder="setPlaceholder(charact.priority)"
                                    :class="checkCharactPriority(charact.charact_id, charact.priority)"
                                >
                                    <el-option v-for="val in charact.charact_value" :value="val" :key="val">
                                    </el-option>
                                </el-select>
                            </span>
                        </div>
                    </div>
                </div>
                <div v-else class="sub-title">
                    <strong>{{ $t('publish.add.select-category-2') }}</strong>
                </div>
            </div>
            <div class="separator"></div>
            <div class="productDescription">
                <div class="title">
                    {{ $t('publish.add.desc') }}
                </div>
                <Box :type="'info'" :active="true">
                    <div slot="box-body">
                        <ul class="rawUl">
                            <li>Evitati linkurile externe in descriere. Acestea pot fi eliminate automat.</li>
                            <li>Tipuri de imagini acceptate: JPG, PNG, GIF.</li>
                        </ul>
                    </div>
                </Box>
                <Editor :text.sync="product.description"></Editor>
            </div>
            <div class="separator"></div>
            <div class="productImages">
                <div class="title">
                    {{ $t('publish.add.images') }}
                </div>
                <Box :type="'info'" :active="true">
                    <div slot="box-body">
                        <ul class="rawUl">
                            <li>Tipuri de imagini acceptate: JPG, PNG, GIF.</li>
                            <li>Dimensiune maxima acceptata: 1200x1200px</li>
                            <li>Dimensiune minima acceptata: 200x200px</li>
                            <li>Imaginile vor fi reprocesate automat si vor fi transformate in format JPG.</li>
                        </ul>
                    </div>
                </Box>
                <div>
                    <div class="sub-title">{{ $t('publish.add.main-image') }}</div>
                </div>
                <el-upload
                    id="mainImageId"
                    list-type="picture"
                    :auto-upload="false"
                    :on-remove="removeMainImage"
                    class="upload-demo"
                    :limit="1"
                    accept="image/*"
                    action="https://jsonplaceholder.typicode.com/posts/"
                >
                    <!-- <div slot="tip" class="el-upload__tip">Doar fisiere de tip JPG, GIF si PNG sunt acceptate</div> -->
                    <el-button size="small" type="primary">{{ $t('publish.add.set-main') }}</el-button>
                </el-upload>
                <div class="separator"></div>

                <div>
                    <div class="sub-title">{{ $t('publish.add.sec-image') }}</div>
                    <Dropzone ref="dzone"></Dropzone>
                </div>
                <div class="separator"></div>
                <div slot="header" class="clearfix">
                    <el-button style="width: 100%" type="primary" icon="el-icon-plus" plain @click.native="saveProduct">
                        {{ $t('publish.add.save') }}</el-button
                    >
                </div>
            </div>
        </el-card>
        <el-dialog :title="$t('publish.add.add-value')" :visible.sync="dialogVisible" class="dialog-xs">
            <span>
                <div class="sub-title">{{ $t('publish.add.value') }}</div>
                <el-input v-model="newValueInput"></el-input>
            </span>

            <Box v-if="modalCharactRestriction == 2" :type="'info'" :active="true" style="margin: 10px 0">
                <div slot="box-body">
                    <ul class="rawUl">
                        <li>Valorile noi necesita aprobare.</li>
                        <li>Valorile noi vor fi asociate produsului dupa aprobare.</li>
                    </ul>
                </div>
            </Box>

            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">{{ $t('general.cancel') }}</el-button>
                <el-button type="primary" @click="addNewCharactVal">{{ $t('general.add') }}</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
const Box = () => import('vue-info-box-element');
const Editor = () => import('@/components/Editor');
const CategorySelect = () => import('@/components/CategoriesSelect');
const DropzoneComp = () => import('@/components/Dropzone/Index.vue');
import { EventBus } from '@/utils/Bus.js';
import { getCategories, getCategCharacts, saveProduct } from '@/api/publicare';

import { charactPriority, setParams } from '@/utils/index';

import { debugPromise, parseCategories } from '@/utils/index';

import { checkMandatoryInfo } from '@/api/cont';

import { getProductsFamilyForCategory, getWarrantyData } from '@/api/produse';

import isEmpty from 'lodash.isempty';
import has from 'lodash.has';
import isUndefined from 'lodash.isundefined';

export default {
    components: {
        Box,
        Editor,
        'c-select': CategorySelect,
        Dropzone: DropzoneComp,
    },
    data() {
        return {
            categories: [],
            test: {},
            product: {
                category: 0,
                title: '',
                brand: '',
                model: '',
                description: '',
                price: '',
                stoc: '',
                characteristics: {},
                dimensions: {},
                oldPrice: '',
                status: '1',
                family_id: '',
                products_weight: 0,
                virtual: 0,
                warranty: 12,
                warranty_pj: 12,
            },
            options: {
                forceInsert: false,
            },
            allCharacteristics: [],
            requiredCharacteristics: [],
            dialogVisible: false,
            newValueInput: '',
            modalCharactId: '',
            modalCharactRestriction: '',
            inputProblems: [],
            familyModeOptions: [
                {
                    value: 1,
                    label: 'Text',
                },
                {
                    value: 2,
                    label: this.$t('publish.add.image'),
                },
            ],
            familyCharacts: [
                // 	{
                // 	charact: '',
                // 	mode: 1
                // }
            ],
            families: [],
            chosenFamily: '',
        };
    },
    methods: {
        removeCharactValue(indexAll) {
            const indexVal = this.allCharacteristics[indexAll].count;

            if ('undefined' != this.allCharacteristics[indexAll]['selected_' + indexVal]) {
                delete this.allCharacteristics[indexAll]['selected_' + indexVal];
            }
            if (this.allCharacteristics[indexAll].count > 0) {
                const count = this.allCharacteristics[indexAll].count - 1;
                this.$set(this.allCharacteristics[indexAll], 'count', count);
            }
            this.$forceUpdate();
        },
        updateCount(index) {
            const count = this.allCharacteristics[index].count + 1;
            this.$set(this.allCharacteristics[index], 'count', count);
            this.$forceUpdate();
        },
        saveProduct(forcePrice = false) {
            if (this.chosenFamily !== '') {
                // params.family_id = this.product.family_id
                // params.family_name = this.product.family_name
                this.product.family_id = this.chosenFamily.match(/\((\d+)\)/);
                this.product.family_id = this.product.family_id[1];

                this.product.family_name = this.chosenFamily.match(/\)\s(.*)$/);
                this.product.family_name = this.product.family_name[1];
            }

            if (!this.validateData()) return false;
            let params = {
                category: this.product.category,
                products_name: this.product.title,
                manufacturer: this.product.brand,
                products_model: this.product.model,
                vendor_ext_id: this.product.vendor_ext_id != null ? this.product.vendor_ext_id : '',
                products_description: this.product.description,
                products_dimensions: this.product.dimensions,
                characteristics: this.product.characteristics,
                pretf: this.product.price,
                stoc: parseInt(this.product.stoc),
                // 'products_weight': this.product.type,
                pret_vechi: this.product.oldPrice,
                status: parseInt(this.product.status),
                garantie: typeof this.product.warranty !== 'undefined' ? this.product.warranty : 12,
                garantie_pj: typeof this.product.warranty_pj !== 'undefined' ? this.product.warranty_pj : 12,
                procesare_in: this.product.procesare_in,
                real_weight: this.product.products_weight,
                virtual: this.product.virtual,
            };

            if ('undefined' !== typeof this.product.family_id && parseInt(this.product.family_id) !== 0) {
                // if (this.chosenFamily !== '') {
                // params.family_id = this.chosenFamily.match(/\((\d+)\)/)
                // params.family_id = params.family_id[1]
                // this.product.family_id = params.family_id

                // params.family_name = this.chosenFamily.match(/\)\s(.*)$/)
                // params.family_name = params.family_name[1]
                // this.product.family_name = params.family_name

                params.family_id = this.product.family_id;
                params.family_name = this.product.family_name;
                params.family_type = this.familyCharacts;
            }
            // todo: continue here
            if (this.options.forceInsert) params.forceInsert = 1;
            if (forcePrice === true) params.overridePrice = 1;
            const objParamas = params;
            params = setParams(params);

            const secondaryImages = this.$refs.dzone.$refs.dropzoneComp.getAcceptedFiles();
            for (const i in secondaryImages) {
                if (secondaryImages[i] == null || secondaryImages[i] === '') continue;
                params.append('file_' + i, secondaryImages[i]);
            }

            if (document.getElementById('mainImageId').querySelector('.el-upload__input').files.length > 0) {
                params.append(
                    'mainImage',
                    document.getElementById('mainImageId').querySelector('.el-upload__input').files[0]
                );
            }

            saveProduct(params)
                .then((res) => {
                    if (parseInt(res.error) > 0) {
                        if (res.message.failed.length > 0) {
                            if (res.message.failed[0].columns[0].msg.includes('Pretul introdus de')) {
                                this.$confirm(
                                    res.message.failed[0].columns[0].msg +
                                        '. ' +
                                        this.$t('publish.add.continue-question'),
                                    this.$t('general.warning'),
                                    {
                                        confirmButtonText: this.$t('general.yes'),
                                        cancelButtonText: this.$t('general.no'),
                                        type: 'warning',
                                    }
                                )
                                    .then((res) => {
                                        this.saveProduct(true);
                                    })
                                    .catch((e) => {
                                        this.$reportToSentry(e, {
                                            extra: {
                                                fn: 'saveProduct',
                                                params: objParamas,
                                            },
                                        });
                                    });
                            }
                            try {
                                this.inputProblems.push(
                                    decodeURIComponent(res.message.failed[0].columns[0].msg.replace(/\+/g, ' '))
                                );
                            } catch (e) {
                                this.inputProblems.push(res.message.failed[0].columns[0].msg.replace(/\+/g, ' '));
                            }
                            this.sbMsg({
                                type: 'warn',
                                message: this.$t('publish.add.error-save'),
                            });
                            return false;
                        } else {
                            this.sbMsg({
                                type: 'warn',
                                message: this.$t('publish.add.error-save-2'),
                            });
                            return false;
                        }
                    }
                    if (!isEmpty(res.message)) {
                        if (res.message.message == 'Total updated: 0') {
                            if (res.message.failed.length == 0) {
                                this.sbMsg({
                                    type: 'warn',
                                    message: this.$t('publish.add.no-product'),
                                });
                                return;
                            } else {
                                const failReason = res.message.failed[0];
                                delete failReason['line'];
                                this.sbMsg({
                                    type: 'warn',
                                    message: failReason.stringify(),
                                });
                            }
                        }
                        this.sbMsg({
                            type: 'success',
                            message: this.$t('publish.add.add-product'),
                        });
                        this.$router.push('/produse/in-asteptare').catch(() => {});
                    }
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'saveProduct',
                            params: objParamas,
                        },
                    });
                    debugPromise('saveProduct', e);
                });
        },
        validateData() {
            this.inputProblems = [];
            if (!this.hasCategory) {
                this.inputProblems.push(this.$t('publish.add.error-category'));
            }
            if (this.product.title.length < 5) {
                this.inputProblems.push(this.$t('publish.add.error-name'));
            }
            // if (this.product.brand.length < 2) this.inputProblems.push(this.$t('publish.add.error-brand'));
            if (this.product.model.length < 3) this.inputProblems.push(this.$t('publish.add.error-model'));

            if (this.product.price < 1 || this.product.price.length < 1) {
                this.inputProblems.push(this.$t('publish.add.error-price'));
            }
            // if (this.product.stoc < 1 || this.product.stoc.length < 1) this.inputProblems.push('Stocul nu poate fi gol sau 0')
            if (isNaN(this.product.stoc) || this.product.stoc.length < 1) {
                this.inputProblems.push(this.$t('products.pendingproduct.error-stock-2'));
            }

            let familyOk = false;
            let emptyFamilyCharacts = 0;

            let familyCharactsEmpty = false;
            for (const i in this.familyCharacts) {
                if (typeof this.familyCharacts[i].charact !== 'undefined' && this.familyCharacts[i].charact !== '') {
                    continue;
                } else emptyFamilyCharacts++;
            }

            if (this.familyCharacts.length === emptyFamilyCharacts) familyCharactsEmpty = true;

            switch (true) {
                case typeof this.product.family_id !== 'undefined' &&
                    this.product.family_id !== '' &&
                    (typeof this.product.family_name === 'undefined' ||
                        this.product.family_name === '' ||
                        this.familyCharacts.length < 1 ||
                        familyCharactsEmpty):
                    this.inputProblems.push(this.$t('publish.add.error-family'));
                    break;
                case typeof this.product.family_name !== 'undefined' &&
                    this.product.family_name !== '' &&
                    (typeof this.product.family_id === 'undefined' ||
                        this.product.family_id === '' ||
                        this.familyCharacts.length < 1 ||
                        familyCharactsEmpty):
                    this.inputProblems.push(this.$t('publish.add.error-family-2'));
                    break;

                case !familyCharactsEmpty &&
                    (typeof this.product.family_name === 'undefined' ||
                        this.product.family_name === '' ||
                        typeof this.product.family_id === 'undefined' ||
                        this.product.family_id === ''):
                    this.inputProblems.push(this.$t('publish.add.error-family-3'));

                default:
                    familyOk = true;
            }
            // console.log('Product characteristics', this.product.characteristics)
            // console.log('Required characts', this.requiredCharacteristics)
            // console.log('All characteritics', this.allCharacteristics)
            if (familyOk) {
                for (const i in this.familyCharacts) {
                    const currentFamilyCharact = this.familyCharacts[i];
                    if (
                        typeof this.familyCharacts[i].charact === 'undefined' ||
                        this.familyCharacts[i].charact === ''
                    ) {
                        this.familyCharacts.splice(i, 1);
                        continue;
                    }

                    let charName = this.allCharacteristics.find(
                        (c) => parseInt(c.charact_id) === parseInt(currentFamilyCharact.charact)
                    );
                    if (charName == null) {
                        this.familyCharacts.splice(i, 1);
                        continue;
                    }
                    charName = charName.charact_name;
                    if (
                        !has(this.product.characteristics, charName) ||
                        Object.keys(this.product.characteristics[charName]).length < 1
                    ) {
                        this.inputProblems.push(
                            this.$t('publish.add.error-charact') +
                                ' <strong>' +
                                charName +
                                '</strong> ' +
                                this.$t('publish.add.error-charact-2')
                        );
                    }
                }
            }

            // Disable required characts
            // for (const x in this.requiredCharacteristics) {
            //     if (
            //         isUndefined(this.product.characteristics[this.requiredCharacteristics[x]]) ||
            //         isEmpty(this.product.characteristics[this.requiredCharacteristics[x]]) ||
            //         (Array.isArray(this.product.characteristics[this.requiredCharacteristics[x]]) &&
            //             this.product.characteristics[this.requiredCharacteristics[x]].filter((i) => i.length > 0)
            //                 .length < 1)
            //     ) {
            //         this.inputProblems.push(
            //             this.$t('publish.add.charact-single') +
            //                 ' <strong>' +
            //                 this.requiredCharacteristics[x] +
            //                 '</strong> ' +
            //                 this.$t('publish.add.error-charact-3')
            //         );
            //     }
            // }
            // if (this.product.description < 5) {
            // 	this.inputProblems.push(
            // 		this.$t('publish.add.error-desc'))
            // }

            // let mainImage = document.getElementById('mainImageId').querySelector('.el-upload__input').files
            // if (mainImage.length < 1) this.inputProblems.push(
            // 	'Imagine principala neselectata sau invalida(doar imagini PNG/JPG/GIF)')
            if (this.inputProblems.length > 0) {
                this.sbMsg({
                    type: 'warn',
                    message: this.$t('publish.add.error-general-save'),
                });
                // document.getElementById('problemsContainer').scrollIntoView();
                window.scrollTo({ top: 0, behavior: 'smooth' }); //A: aia veche nu afisa erorile

                return false;
            }

            return true;
        },
        getCategories() {
            getCategories()
                .then((res) => {
                    if (res == null || typeof res.message === 'undefined') return false;
                    this.categories = parseCategories(res.message.categories);
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'getCategories',
                        },
                    });
                    debugPromise('getCategories', e);
                });
        },
        parseCategories(categories) {
            const parsedCategories = [];
            for (const i in categories) {
                const cat1 = {};
                cat1.label = i;
                cat1.value = i;
                cat1.children = [];
                for (const j in categories[i]) {
                    const cat2 = {};
                    if (typeof categories[i][j] === 'object') {
                        cat2.label = j;
                        cat2.value = j;
                        cat2.children = [];
                        for (const k in categories[i][j]) {
                            const cat3 = {};
                            cat3.label = k;
                            cat3.value = categories[i][j][k];
                            cat2.children.push(cat3);
                        }
                    } else {
                        cat2.label = j;
                        cat2.value = categories[i][j];
                    }
                    cat1.children.push(cat2);
                }
                parsedCategories.push(cat1);
            }
            this.categories = parsedCategories;
        },
        getCategCharacts(categId) {
            getCategCharacts({
                categ_id: categId,
            })
                .then((res) => {
                    if (typeof res.message === 'undefined') return false;
                    this.allCharacteristics = Object.values(res.message).sort((a, b) => {
                        return b.priority - a.priority;
                    });
                    this.requiredCharacteristics = [];
                    // Adding the required cahracteristics to array
                    for (const x in this.allCharacteristics) {
                        this.allCharacteristics[x].count = 1;
                        this.product.characteristics[this.allCharacteristics[x].charact_name] = {};
                        if (this.allCharacteristics[x].priority > charactPriority()) {
                            this.requiredCharacteristics.push(this.allCharacteristics[x].charact_name);
                        }
                    }

                    this.getFamilies();
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'getCategCharacts',
                            params: {
                                categ_id: categId,
                            },
                        },
                    });
                    debugPromise('getCategCharacts', e);
                });
        },
        setPlaceholder(priority) {
            priority = parseInt(priority);
            switch (true) {
                // case priority > charactPriority():
                //     return this.$t('publish.add.required-charact');
                //     break;
                default:
                    return this.$t('publish.add.optional-charact');
            }
        },
        checkCharactPriority(charId, priority) {
            priority = parseInt(priority);
            switch (true) {
                // case priority > 19:
                //     return {
                //         'is-danger': true
                //     };
                //     break;
                default:
                    return {
                        'is-success': false,
                    };
            }
        },
        setCharactVal(index, val, charact) {
            if (val === '') delete this.product.characteristics[charact.charact_name][index];
            else this.product.characteristics[charact.charact_name][index] = val;
        },
        openModal(charactId, restriction) {
            this.modalCharactId = charactId;
            this.modalCharactRestriction = restriction;
            this.dialogVisible = true;
        },
        addNewCharactVal() {
            if (this.newValueInput.length < 1) return false;
            const vm = this;
            this.allCharacteristics = this.allCharacteristics.map((char) => {
                if (parseInt(char.charact_id) === parseInt(vm.modalCharactId)) {
                    char.charact_value.push(vm.newValueInput);
                }
                return char;
            });
            this.dialogVisible = false;
            this.modalCharactId = '';
            this.modalCharactRestriction = '';
            this.newValueInput = '';
        },
        removeMainImage() {
            document.getElementById('mainImageId').querySelector('.el-upload__input').value = '';
        },
        addFamilyCharact() {
            this.familyCharacts.push({
                charact: '',
                mode: 1,
            });
        },
        removeFamilyCharact(index) {
            this.familyCharacts.splice(index, 1);
        },
        async categorySelected(categ) {
            if (typeof categ.categories_id !== 'undefined') {
                this.product.category = categ.categories_id;
                if (parseInt(categ.categories_id) !== 0) {
                    this.getCategCharacts(categ.categories_id);
                    await this.getWarrantyData(categ.categories_id);
                } else {
                    this.allCharacteristics = [];
                    this.requiredCharacteristics = [];
                }
            }
        },
        getWarrantyData(categId) {
            return getWarrantyData({ id: categId }).then((res) => {
                const responseData = res.message;
                if (responseData == false) return;

                if (responseData.warranty_pf > 0 && warranty_pf.value.length == 0) {
                    this.product.warranty = responseData.warranty_pf || 12;
                }
                if (responseData.warranty_pj > 0 && warranty_pj.value.length == 0) {
                    this.product.warranty_pj = responseData.warranty_pj || 12;
                }
            });
        },
        async checkMandatoryInfo() {
            try {
                await checkMandatoryInfo();
            } catch (e) {
                this.$router.push('/cont/profil-public').catch(() => {});
            }
        },
        getFamilies() {
            getProductsFamilyForCategory({
                categId: this.product.category,
            }).then((res) => {
                this.families = res.message.families;
            });
        },
        onFamilySelect(id) {
            const selectedFamily = this.families.find((family) => parseInt(family.id) === parseInt(id));
            if (selectedFamily == null) {
                this.sbMsg({
                    type: 'warn',
                    message: 'Nu am gasit familia selectata',
                });
                return false;
            }
            this.product.family_name = selectedFamily.name;
            if (selectedFamily.characteristics.length > 0) {
                this.familyCharacts = selectedFamily.characteristics.map((c) => {
                    return {
                        charact: parseInt(c.charact),
                        mode: parseInt(c.mode),
                    };
                });
            }
        },
        addNewFamily() {
            window.open('/produse/familii', '_blank');
        },
    },
    watch: {
        'product.family_id'(val) {
            const selectedFamily = this.families.find((family) => parseInt(family.id) === parseInt(val));
            if (selectedFamily == null) return false;
            this.familyCharacts = selectedFamily.characteristics;
        },
    },
    computed: {
        familyTypeOptions() {
            if (this.allCharacteristics.length < 1) return [];
            return this.allCharacteristics
                .filter((item) => {
                    return (
                        typeof item.charact_id !== 'undefined' &&
                        item.charact_id !== '' &&
                        typeof item.charact_name !== 'undefined' &&
                        item.charact_name !== ''
                    );
                })
                .map((item) => {
                    return {
                        value: parseInt(item.charact_id),
                        label: item.charact_name,
                    };
                });
        },
        familyCharactsComputed() {
            return this.familyCharacts.length;
        },
        hasCategory() {
            return (
                typeof this.product.category !== 'undefined' &&
                this.product.category !== '' &&
                this.product.category !== 0
            );
        },
        characterLimits() {
            return this.$store.state.app.charactersLimit;
        },
    },
    created() {
        // this.checkMandatoryInfo()
        // this.getCategories()
    },
    beforeDestroy() {
        EventBus.$off();
    },
};
</script>
<style rel="stylesheet/scss" lang="scss">
@import './src/styles/modules/produse.scss';
</style>
<style>
.delinput input.el-input__inner {
    text-align: left !important;
}
</style>
<style scoped>
.delinput {
    width: 100%;
}

.small {
    font-size: 0.8rem;
    margin-top: 0.5rem;
    font-style: italic;
}

.red-text {
    color: #f56c6c;
}

.info-text {
    /** some nice blue color*/
    color: #409eff;
}
</style>
